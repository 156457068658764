<template>
    <div class="about">
        <appPopUp :popUp="show" :btnPrev="btnPrev" :btnNext="btnNext" :text="text" :title="title" :img="img" :id="id" :getAllId="getAllId" @closePopUp="closePopUp" @contentId="contentId" />
        <div v-for="item in about" :key="item.id" class="about__box" :class="item.color" @click="showPopUp(item.id)">
            <div class="about__content">
                <div class="about__title" v-html="item.title"></div>
                <p class="about__text" v-html="item.preview_text"></p>
            </div>
            <button class="about__button"><i class="far fa-angle-right"></i></button>
            <img class="about__img" :src="item.preview_image" alt="">
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    import VueAxios from 'vue-axios'
    import PopUp from '@/components/PopUp'
    import { HTTP, Routes } from '../API'
    export default {
        components: {
            appPopUp: PopUp,
            
        },
        props:{
            langContent:{type: String, default: 'ru'},
        },
        data(){
            return{
                blockScroll: false,
                removeScrollButton: true,
                activeButton: false,
                show: false,
                text:'',
                title:'',
                img:'',
                id: 0,
                
                about:[],
                allId:[],
                getAllId: null,
                pagetitle: null,
                nextButton: null,
                btnPrev: 'Предыдущая',
                btnNext: 'Следующая'
                // show: false
            }
        },
        methods:{
            closePopUp(popUp){
                this.show = !popUp
                this.$router.push({ path: `/${this.langContent}/about` })
                this.blockScroll = true
                this.$emit('noScroll', this.blockScroll)
            },
            contentId(idContent){
                let index = idContent
                // Всего контента
                let allСontent = this.about.length
                // console.log(index)
                if(allСontent !== index){
                    this.showPopUp(index)
                }
            },
            showPopUp(index){
                let number = this.about.findIndex( item =>{
                    return item.id == index
                })

                let obj = new Object();
                this.about.forEach(element => {
                    let id =  element.id
                    obj.id = id
                    this.allId.push(Object.assign({}, obj))
                });
                this.getAllId  = this.allId
                this.allId = []

                this.show = true
                this.text = this.about[number].detail_text
                this.title = this.about[number].title
                this.img = this.about[number].detail_image
                this.id = Number(this.about[number].id)
                let code = this.about[number].code
                // Заблокировать скролл
                this.$emit('noScroll', this.blockScroll)
                this.writeIdArticles(code)
            },
            // Записать id статьи
            writeIdArticles(code){
                this.$router.push({ path: `/${this.langContent}/about/${ code }` })
            },
            getContent(){
                HTTP.get(Routes.about)
                    .then( response => {
                        if(response.data.err === 0){
                            // О компании
                            this.about = response.data.about.items
                            // console.log('this.about: ', this.about);
                            this.btnNext = response.data.about.btn_elem_next
                            this.btnPrev = response.data.about.btn_elem_previos
                            // Заголовок
                            this.pagetitle = response.data.about.title
                            this.nextButton = response.data.about.btn_next
                            // setTimeout(() => {
                            //     this.$emit('buttonNextScroll', this.nextButton)
                            // },0)
                        }
                })
                .catch( error => {
                    console.log(error);
                });
            }

            
        },
        mounted(){
            this.getContent();
            // axios.get(`https://macchoco.ru/includes/api/getData.php?type_data=about&lang=${this.langContent}`)
            // .then( response => {
            //     if(response.data.err === 0){
            //         // О компании
            //         this.about = response.data.about.items
            //         // Заголовок
            //         this.pagetitle = response.data.about.title
            //         // this.nextButton = response.data.about.btn_next
            //         // setTimeout(() => {
            //         //     this.$emit('buttonNextScroll', this.nextButton)
            //         // },0)
            //     }
            // })
            // .catch( error => {
            //     console.log(error);
            // });

            // Заголовок 
            let aboutTitle = document.getElementsByClassName('about__title')
            setTimeout(()=>{
                for (let i = 0; i < aboutTitle.length; i++) {
                    aboutTitle[i].classList.add('animation-text-active')
                }
            },450)
            // Текст
            let aboutText = document.getElementsByClassName('about__text')
            setTimeout(()=>{
                for (let i = 0; i < aboutText.length; i++) {
                    aboutText[i].classList.add('animation-text-active')
                }
            },650)
            // Показать статью по прямой ссылке
            setTimeout(()=>{
                 this.about.forEach(el => {
                    if(el.code === String(this.$route.params.id) ){
                        let index = el.id
                        this.showPopUp(index)
                        return false
                    }
                });
            }, 200)
        },
        beforeCreate(){
            this.removeScrollButton = true
            this.$emit('removeButtonScrollFooter',this.removeScrollButton)
   
            this.show = true
            this.$emit('addLogoFooter',this.show)
            this.removeScrollButton = false
            this.$emit('removeButtonScrollFooter',this.removeScrollButton)
        },
        destroyed(){
            this.show = false
            this.$emit('addLogoFooter',this.show)
            this.removeScrollButton = true
            setTimeout(()=>{
                this.$emit('removeButtonScrollFooter',this.removeScrollButton)
            },500)
        },
    }
</script>
