<template>
    <div id="popUp" class="background-popUp" @click="closePopUpClick" :class="{showPopUp:popUp}">
        <div id="box-popUp" class="popUp" :class="{showPopUpContent:popUp}">
            <button class="popUp__button-close" @click="closePopUp">
                <i class="far fa-times"></i>
            </button>
            <div class="popUp__content" :class="{changeContent:active}">
                <div class="popUp__title" v-html="title"></div>
                <div class="popUp__content-main">
                    <div class="popUp__img">
                        <img :src="img" alt="">
                    </div>
                    <div class="popUp__text__container">
                        <div class="popUp__text" v-html="text"></div>
                    </div>
                </div>
            </div>
            <div class="popUp__navigation">
                <button class="popUp__button" @click="prevContent">
                    <span class="popUp__button-icon"><i class="far fa-angle-left"></i></span> 
                    <span class="popUp__button-text">{{btnPrev}}</span>
                </button>
                <button class="popUp__button" @click="nextContent">
                    <span class="popUp__button-text">{{btnNext}}</span>
                    <span class="popUp__button-icon"><i class="far fa-angle-right"></i></span>
                </button>
            </div>  
        </div>
    </div>
</template>
<script>
export default {
    // props:["getAllId"],  
    props: {
        popUp:{type: Boolean,required: false},
        text:{type: String},
        title:{type: String},
        img:{type: String},
        id:{type: Number},
        btnNext:{type: String},
        btnPrev:{type: String},
        getAllId:{
            type: Array,
            default: function(){
                return  {
                   id
                }
            }
        }
    },
      
    data(){
        return{
             active: false,
             escButton: false,
             blockScroll: false,
        }
    },
    methods:{
        // Закрыть попап по клику вне его
        closePopUpClick(e){
            if(e.target.classList.contains('showPopUp') === true){
                this.closePopUp()
            }
        },
        closePopUp(){
            this.$emit('closePopUp',this.popUp)
        },
       prevContent(){
            this.change()
            let index = this.getAllId.findIndex( item =>{
                return item.id == this.id
            })
            index --
            
            let idContent
            if(index < 0){
                let all = this.getAllId.length
                all --
                idContent = this.getAllId[all].id 
            } else{
                idContent = this.getAllId[index].id 
            }
            this.$emit('contentId',idContent)
       },
       nextContent(){
            this.change()
            let index = this.getAllId.findIndex(item =>{
                return item.id == this.id
            })
            index ++

            let idContent
            if(index === this.getAllId.length){
                idContent = this.getAllId[0].id 
            } else {
                idContent = this.getAllId[index].id 
            }
            this.$emit('contentId',idContent)
            
       },
       change(){
           this.active = true
           setTimeout(() =>{
               this.active = false
           },700)
       }
    },
    created() {
        // Закрыть popUp по esc
        document.onkeydown = evt => {
            evt = evt || window.event;
            if (evt.keyCode == 27 && this.popUp) {
                this.closePopUp()
            }
        }

    }
}
</script>